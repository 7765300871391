import React from 'react';
import { Link, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';

const NotFoundPage = ({}: PageProps) => {
  return (
    <Layout>
      <Seo title="Not found" />
      <div className="bg-white  rounded-b-3xl overflow-hidden">
        <div className="min-h-[80vh] mx-2 md:mx-8 py-32 lg:mx-16 max-w-screen-xl 2xl:mx-auto 2xl:px-16">
          <h1>Page not found</h1>
          <p>
            Sorry{' '}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{' '}
            we couldn’t find what you were looking for.
            <br />
            {process.env.NODE_ENV === 'development' ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
